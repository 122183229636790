import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { isMobile } from 'react-device-detect'

// TODO: clashes with colenio-tiles.sass
// const ImageWrapper = ({ src, alt }) => {
//   const gatsbyImage = getImage(src)
//   return !!gatsbyImage ? (
//     <GatsbyImage image={gatsbyImage} alt={alt} />
//   ) : (
//     <img src={src} alt={alt} />
//   )
// }

const InformationCard = ({ img, title, content, link, isSmall }) => {
  const className = `colenio-tile${isSmall ? '-small' : ''}`
  // noinspection CheckTagEmptyBody
  const linkContent = !!link ? (
    <div>
      Mehr lesen <i className="icon fa fa-arrow-right"></i>
    </div>
  ) : null
  const cardBannerContent =
    !!linkContent && isMobile ? (
      <a href={link}>
        {title}
        {linkContent}
      </a>
    ) : (
      <span>
        {title}
        {linkContent}
      </span>
    )

  const cardContent = (
    <div className={className}>
      <GatsbyImage
        image={getImage(img)}
        alt=""
        objectFit="cover"
        className="colenio-gatsby-bg-image"
      />
      {cardBannerContent}
      <p>{content}</p>
    </div>
  )

  return (
    <div>
      {!!linkContent && !isMobile ? (
        <a href={link}>{cardContent}</a>
      ) : (
        cardContent
      )}
    </div>
  )
}

InformationCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.string,
  isSmall: PropTypes.bool,
}

export default InformationCard
