import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
const Hero = ({ title, subtitle, img, newsContent, newsLink }) => {
  const styles = {
    position: 'absolute',
  }
  const heroClassName = 'colenio-gatsby-bg-image'

  // default hero image -> has to be in src/img
  const imageJsx =
    img == null ? (
      <StaticImage
        src="../img/default-hero.jpg"
        alt=""
        objectFit="cover"
        className={heroClassName}
        style={styles}
      />
    ) : (
      <GatsbyImage
        image={getImage(img)}
        alt=""
        objectFit="cover"
        className={heroClassName}
        style={styles}
      />
    )

  const newJsx = newsLink ? (
    <a href={newsLink}>{newsContent}</a>
  ) : (
    <span className="has-text-primary">{newsContent}</span>
  )

  return (
    <div>
      <div className="hero colenio-hero">
        {imageJsx}
        <div className="hero-body colenio-hero-body">
          <p className="title has-text-primary is-size-1">{title}</p>
          <p className="text has-text-white is-size-4">{subtitle}</p>
        </div>
      </div>
      {newsContent && (
        <div>
          <div className="has-background-grey-dark is-size-3 padding-1 has-text-white has-text-centered">
            <p>NEWS: {newJsx}</p>
          </div>
        </div>
      )}
    </div>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  noImg: PropTypes.bool,
}

export default Hero
