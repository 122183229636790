import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ContentHero from '../components/ContentHero'
import InformationCard from '../components/InformationCard'
import Hero from '../components/Hero'

const IndexPage = props => {
  const { data } = props
  return (
    <Layout>
      <Hero
        title="getting digital things done" // new-ci "colenio GmbH & Co. KG"
        subtitle="Ihr Wegbegleiter in die digitale Zukunft" // new-ci ""
        img={props.data.hero.childImageSharp}
        newsContent="Neues spannendes Referenzprojekt mit Carl Cloos Schweisstechnik GmbH online" // new-ci "colenio mit neuem Markenauftritt"
        newsLink=""
      />

      <div className="container">
        <ContentHero
          title="Wer sind wir"
          subtitle="Wir sind ein innovativer IT-Dienstleister mit Standort Siegburg (bei Köln), der gemeinsam mit seinen mehr als 150 Kunden digitale Veränderungsvorhaben zum Erfolg führt.
  Unsere Kunden schätzen insbesondere den durchgängig hohen Qualitätsanspruch der Ergebnisse und die thematische Breite der Beratungsleistungen."
        />

        <div className="columns">
          <div className="column is-third">
            <InformationCard
              img={data.portfolio.childImageSharp}
              title="Unser Portfolio"
              link="/portfolio"
              content="  Unser Portfolio reicht von der Entwicklung großer Software- und Cloudarchitekturen, DevOps und Data Science
        über das gesamte konzeptionelle und technische Spektrum der Informations- und Netzwerksicherheit bis zu
        IT-spezifischen Rechts- und Compliance-Aspekten wie Datenschutz und Consent Management."
            />
          </div>
          <div className="column is-third is-hidden">
            <InformationCard
              img="/img/customer-color.jpg"
              title="Unsere Kunden"
              link="/customers"
              content=" Unsere Kunden kommen aus dem  klassischen Maschinen- und Anlagenbau, aus der Bau- und Landmaschinenbranche, aus dem Versicherungswesen,
        bis hin zu Verlagshäusern und öffentlichen Organen.
        Auf Basis unserer Breite der Beratungsleistungen bedienen wir ein breites Spektrum an Branchen."
            />
          </div>
          <div className="column is-third">
            <InformationCard
              img={data.career.childImageSharp}
              title="Bei uns arbeiten"
              link="/career"
              content=" Wir wollen mehr sein als nur ein Job, wir möchten gemeinsam etwas bewegen.
        Wir bieten einen sehr hohen Grad an individueller Freiheit bei der Gestaltung der eigenen Arbeitsweise.
        Ob Home Office oder Büro, ob früh oder eher spät: Deine Sache.
        Wer aufhört besser zu werden, hat aufgehört gut zu sein - ein Tag pro Woche gehört daher der Weiterbildung,
        z.B. für neue Tools und Technologien, Konferenzen, Community Work oder persönlichen Austausch im Team."
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
IndexPage.propTypes = {
  data: PropTypes.shape({
    allIndexYaml: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    hero: file(relativePath: { eq: "colenio_lighthouse_picture_grey.jpeg" }) {
      # new-ci "Web01rechts.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1 # 4.1
          transformOptions: { fit: COVER, cropFocus: NORTHEAST }
        )
      }
    }
    portfolio: file(relativePath: { eq: "portfolio2-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 600, width: 700)
      }
    }
    career: file(relativePath: { eq: "code-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 600, width: 700)
      }
    }
  }
`
